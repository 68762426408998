<template>
  <div class="earnmoney">
    <div class="top">
      <div class="tip" @click="desced">说明</div>
      <div class="yxj">意向金</div>
      <div class="num">￥{{earnTotal}}</div>
      <div class="oper">
        <div @click="vocherTrans">
          <img src="@/assets/image/my/pz.png" alt />
          <span>凭证转入余额</span>
        </div>
        <div class="btn" @click="transMoney">转余额</div>
      </div>
    </div>
    <!-- list -->
    <div class="list-box">
      <van-list v-model:loading="loading"  :finished="finished" finished-text="没有更多了" @load="getList">
        <div class="list" @click="goUrl(item)" v-for="item in dataList" :key="item">
          <div class="left">
            <div class="title">{{getStatus(item)}}:</div>
            <div class="time">{{item.create_at}}</div>
          </div>
          <div class="right">
            <div>
              <div class="paychar add" v-if="item.purpose_status == 0">+{{item.purpose_money}}</div>
              <div class="paychar" v-else>-{{item.purpose_money}}</div>
            </div>
            <img src="../../assets/image/my/y.png" alt />
          </div>
        </div>
      </van-list>
    </div>

    <!-- pz -->
    <rConfirm
      ref="pzconfim"
      @confirm="confirmpurpose"
      slots
      titles="凭证转余额"
      :disabled="vouchNumber.trim() && istrans ? false : true"
    >
      <div class="pztrans">
        <p>确认是否将此凭证的意向金转入余额，转入余额后可 用于抵扣租金或日常费用</p>
        <div class="pznumber">
          <span>凭证号:</span>
          <input v-model="vouchNumber" placeholder="请输入凭证号" />
          <span @click="checkvouchNumber">校验</span>
        </div>
        <div class="tip">
          <span>注:</span>
          <span>每个凭证号只能使用一次，使用后凭证将作废</span>
        </div>
      </div>
    </rConfirm>
    <!-- 说明 -->
    <rConfirm ref="desc" slots titles="说明" :btn="false">
      <div class="desc">
        <p>1、意向金：意向金为商户在入驻市场前与市场提前预定租赁商铺，并向市场缴纳的意向费用</p>
        <p>2、使用：意向金在商户入驻市场后可以将意向金转到个人余额进行抵扣租金或日常费用。也可以到市场管理员处申请退款</p>
        <p>3、其它：若缴纳意向金的不是本人，可以找到缴纳人，拿到缴费凭证上的凭证号，通过凭证号也可以将意向金转入个人余额</p>
      </div>
    </rConfirm>
  </div>
</template>
<script setup>
import { Toast } from 'vant';
import {
 inject, reactive, ref, nextTick, onMounted
} from 'vue';

const _this = inject('plugin');
const $require = inject('$require');
let { router } = inject('ctx');
let pzconfim = ref(null);
let desc = ref(null);

// 列表
let loading = ref(false);
let finished = ref(false);
let searchForm = reactive({ pageNum: 0, pageSize: 10, pay_status: 10 });
let dataList = ref('');
function getList() {
  searchForm.pageNum++;
  $require.HttpGet('/h5/purpose/list', searchForm)
    .then(res => {
      loading.value = false;
      if (res.data) {
        if (res.data.list) {
            dataList.value = res.data.list;
            if (res.data.list.length < searchForm.pageSize) {
              finished.value = true;
            }
        } else {
          finished.value = true;
        }
      }
    })
    .catch(() => {
      finished.value = true;
    });
}
function getStatus(row) {
      if (row.pay_status == 0 && row.purpose_status != -1) {
        return '待支付';
      }
      switch (row.purpose_status) {
        case 0:
          return '待使用';
        case 1:
          return '已使用';
        case 2:
          return '转用';
        case 3:
          return '驳回带退款';
        case 4:
          return '待退款';
        case 5:
          return '完成';
        case -1:
          return '作废';
        default:
          return '';
      }
    }
// 总金额
let earnTotal = ref(0);
function getTotal() {
  $require.HttpGet('/h5/purpose/purposeTotal')
  .then(res => {
    earnTotal.value = res.data;
    console.log(res);
  });
}
getTotal();
// getList()
//   所有转余额
function transMoney() {
  _this.myconfirm(
    '确认是否将意向金转入余额，转入余额后可用于 抵扣租金或日常费用',
    '意向金转余额'
  ).then((res) => {
      $require.HttpPost('/h5/purpose/allPurposeToBalance').then(res => {
        getList();
        getTotal();
      });
  })
  .catch(err => {
    console.log(err);
  });
}
let vouchNumber = ref('');
// 凭证转余额
function vocherTrans() {
  pzconfim.value.fade();
}
// 是否能转余额
let istrans = ref(false);
function checkvouchNumber() {
  istrans.value = false;
  if (!vouchNumber.value) return Toast('请输入凭证号');
  $require.HttpPost('/h5/purpose/purposeToBalance', { purpose_no: vouchNumber.value })
    .then(res => {
      if (res.data) {
        if (res.data.purpose_status == 0 && res.data.pay_status == 10) {
          istrans.value = true;
        } else {
          Toast('该凭证不可用');
        }
      }
    });
}
function confirmpurpose() {
  $require.HttpPost('/h5/purpose/purposeToBalance', { purpose_no: vouchNumber.value, purpose_status: 1 })
    .then(res => {
      Toast('转入成功');
      pzconfim.value.close();
      getList();
      getTotal();
      console.log(res);
    });
}

function goUrl(row) {
  router.push(`/account/earnMoneyDetail?id=${row.purpose_id}`);
}
// 说明
function desced() {
  desc.value.fade();
  // console.log(desc.value);
}

</script>
<style lang="scss" scoped>
.earnmoney {
  padding: 20px 24px 0 24px;
  box-sizing: border-box;
  .top {
    width: 100%;
    // height: 380px;
    background: url("../../assets/image/my/yxjbg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 31px 52px;
    box-sizing: border-box;
    .tip {
      width: 100%;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      opacity: 0.8;
      text-align: right;
    }
    .yxj {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
    .num {
      font-size: 60px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      margin: 39px 0 66px 0;
    }
    .oper {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #eaf4df;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      span {
        vertical-align: middle;
      }

      img {
        width: 30px;
        height: 30px;

        margin-right: 15px;
      }
      .btn {
        width: 250px;
        height: 98px;
        background: #ffffff;
        border-radius: 49px;
        text-align: center;
        line-height: 98px;
        color: #85c226;
      }
    }
  }
  .list-box {
    width: 100%;
    margin-top: 20px;
    background: #ffffff;
    border-radius: 20px;
    padding: 0 30px;
    box-sizing: border-box;
    .list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // height: 126px;
      border-bottom: 1px solid #efefef;
      padding: 30px 0;
      box-sizing: border-box;
      &:last-child {
        border-bottom: none;
      }
      img {
        width: 13px;
        height: 22px;
      }
      .left {
        .title {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
        }
        .time {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          margin-top: 24px;
        }
      }
      .right {
        display: flex;
        align-items: flex-start;
        text-align: right;
        .paychar {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #000000;
        }
        .add {
          color: #85c226;
        }
        img {
          width: 13px;
          height: 22px;
          margin-left: 28px;
          vertical-align: middle;
          margin-top: 8px;
        }
      }
    }
  }
  .pztrans {
    width: 100%;
    padding: 49px 31px;
    box-sizing: border-box;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    .pznumber {
      width: 100%;
      height: 96px;
      background: #f9f9f9;
      border-radius: 48px;
      margin-top: 40px;
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 0 29px;
      box-sizing: border-box;
      input {
        height: 100%;
        background: transparent;
        flex: 1;
        padding-left: 20px;
        &::placeholder {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #d8d8d8;
        }
      }
    }
    .tip {
      margin-top: 20px;
      span {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
        &:nth-child(1) {
          color: #85c226;
        }
      }
    }
  }
}
.desc {
  font-size: 24px;
  padding: 0 25px;
  line-height: 50px;
}
</style>
